<template>
  <div class="old">
    <div class="bg_block">
      <div class="brief">
        <div class="b_tit">赛虎科技</div>
        <div class="b_tit">全面的定制化专项安全服务</div>
        <div class="b_subtit">
          以保障风险管控效果为目标，提供体系化、常态化的安全综合服务，构建7x24小时全维度的安全服务能力，实现快速处置的业务闭环持续不断保障客户网络安全。
        </div>
        <el-button type="primary" size="mini" @click="$myutils.jumpto('apply')">申请试用</el-button>
      </div>
    </div>
    <div class="first_block">
     <div class="img_bg"></div>
      <div class="main_tit">
        <div>专 项 安 全 服 务</div>
        <div class="line"></div>
        <div>SERVICE CONTENT</div>
      </div>
      <div class="the_cnt" data-aos="fade-up">
        <div class="one_item" v-for="i in bgImg1" :key="i" :style="`background: url(${require('../assets/old/' + i.name + '-bg.jpg')}) no-repeat;`">
          <img :src="getImg(i.name)" alt="" height="40">
          <div class="sub_tit">{{i.name}}</div>
          <div class="line"></div>
          <div class="detail">{{i.cnt}}</div>
        </div>
      </div>
    </div>
    <div class="third_block">
      <div class="img_bg"></div>
      <div class="main_tit">
        <div>服 务 价 值</div>
        <div class="line"></div>
        <div>SERVICE VALUE</div>
      </div>
      <div class="the_cnt">
        <div class="one_item" data-aos="fade-up" data-aos-delay="100">
          <img src="../assets/old/1定制化服务方案.png" alt="" height="80">
          <div class="sub_tit">
            定制化服务方案
          </div>
        </div>
        <div class="one_item" data-aos="fade-up" data-aos-delay="200">
          <img src="../assets/old/2深度风险发现能力.png" alt="" height="80">
          <div class="sub_tit">
            深度风险发现能力
          </div>
        </div>
        <div class="one_item" data-aos="fade-up" data-aos-delay="300">
          <img src="../assets/old/3超细颗粒度检测.png" alt="" height="80">
          <div class="sub_tit">
            超细颗粒度监测
          </div>
        </div>
        <div class="one_item" data-aos="fade-up" data-aos-delay="400">
          <img src="../assets/old/4多维度能力模型.png" alt="" height="80">
          <div class="sub_tit">
            多维度能力模型
          </div>
        </div>
        <div class="one_item" data-aos="fade-up" data-aos-delay="500">
          <img src="../assets/old/5聚合型保障输出.png" alt="" height="80">
          <div class="sub_tit">
            聚合型保障输出
          </div>
        </div>
        <div class="one_item" data-aos="fade-up" data-aos-delay="600">
          <img src="../assets/old/6整体安全能力提升.png" alt="" height="80">
          <div class="sub_tit">
            整体安全能力提升
          </div>
        </div>
      </div>
    </div>
    <div class="seventh_block">
      <div class="img_bg"></div>
      <div class="main_tit">
        <div>客户收益</div>
        <div class="line"></div>
        <div>CUSTOMER INCOME</div>
      </div>
      <div class="the_cnt" data-aos="flip-up">
        <img v-for="i in 4" :key="i" :src="getImg(i)" width="240">
      </div>
    </div>
  </div>
</template>

 <script>
 export default {
  data(){
    return{
      bgImg1:[
        {name:'渗透测试',cnt:'使用攻击者常用的漏洞发现技术和攻击技术，对用户信息化建设的安全能力进行深入的探测，发现系统最脆弱的环节。'},
        {name:'风险评估',cnt:'多方位进行风险识别和评估，并提供相应的建议和措施。通过风险评估找出可能导致漏洞和攻击的弱点，并为优先处理风险提供依据。'},
        {name:'安全巡检',cnt:'通过全面审查发现和解决潜在的安全风险，以保护系统和网络免受攻击和数据泄露，确保系统安全稳定，提高防御水平，保护用户和机构的利益。'},
        {name:'应急处理',cnt:'是一种针对安全事件的迅速响应和处理过程。旨在最小化安全事件对系统和数据的影响，并恢复系统的正常运行。'},
        {name:'安全保障',cnt:'在特殊重要时期，保障用户信息系统安全稳定运行。提前识别可能存在的安全威胁，制定相应的安全保障策略。加强安全巡检和监控，及时发现并处理异常行为和安全事件。'},
        {name:'安全培训',cnt:'通过讲解国家网络安全政策法规、网络安全事件案例、网络安全技术操作等内容，使学员真正具备网络安全意识与网络防御技术要领，加强规避风险的能力。'},
        {name:'攻防演练',cnt:'模拟各类网络安全事件的发生，排除安全隐患并恢复使用。通过攻防演练，可以提升网络安全应急响应能力，进一步强化保障业务系统的稳定、安全运行。'}
      ]
    }
  },
  methods:{
    getImg(item) {
      return require('../assets/old/'+`${item}`+'.png');
    },
  },
  mounted(){
    this.$aos.init({
      offset:200,
      duration: 600,
      easing: 'ease-in-sine',
      disable: 'phone'
    });
  }
 }
 </script>

 <style lang="scss" scoped>
  .old{
    color: #fff;
    .bg_dot{
      position: absolute;
      left:0px;
      top:0px;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
    .bg_block{
      width: 100%;
      background: url(../assets/comper/bg.png) no-repeat;
      background-size:100% 100%;
      .brief{
        color: $light-base;
        padding: 200px 0 220px 12vw;
        width: 50vw;
        font-size: 1.1vw;
        .b_tit{
          font-size: 3vw;
          font-weight: bold;
        }
        .b_subtit{
          margin: 30px 0 60px 0;
        }
      }
    }
    // 复用类
    .main_tit{
      text-align: center;
      margin-bottom: 40px;
      div{
        &:first-child{
          font-size: 2.2vw;
          font-weight: bold;
        }
        &:last-child{
          color: $gray-base;
          font-size: 0.9vw;
        }        
      }
      .line{
        width: 260px;
        height: 1px;
        background-color: #fff;
        margin: 6px auto;
      }
    }
    .one_item{
      padding: 30px 20px;
      width: 240px;
      height: 300px;
      text-align: center;
      background-size: 100% 100%!important;
      margin: 0 10px;
      margin-bottom: 20px;
      z-index: 99;
      .sub_tit{
        margin: 8px 0;
        font-weight: bold;
      }
      .line{
        width: 100%;
        height: 1px;
        background-color: #fff;
        margin: 6px auto;
      }
      .detail{
        text-align: left;
      }
    }
    .img_bg{
      position: absolute;
      left:0px;
      top:0px;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
    //单独模块
    .first_block,.third_block,.seventh_block{
      background:#0b1b33;
      background-size:100% 100%;
      padding: 80px 0;
      position: relative;
    }
    .seventh_block{
      .img_bg{
        background: url('../assets/第三部分.png');
        background-size: contain;
      }
    }
    .first_block{
      .the_cnt{
        width: 1080px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;      
      }
      .img_bg{
        background: url('../assets/第一部分.png');
        background-size: contain;
      }
    }
    .third_block{
      .the_cnt{
        width: 80%;
        display: flex;
        justify-content: space-around;
        margin: 0 auto;
        flex-wrap: wrap;
        .one_item{
          width: 30%;
          height: 200px;
          .sub_tit{
            span{
              font-size: 1.2vw;
              color: #f48d06;
              font-weight: 900;
              margin-right: 6px;
            }
          }
        }
      }
      .img_bg{
        background: url('../assets/第二部分.png');
        background-size: contain;
      }
    }
    .seventh_block{
      .the_cnt{
        width: 80%;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
      }
      .img_bg{
        background: url('../assets/第二部分.png');
        background-size: contain;
      }
    }
    @media screen and (max-width: 1080px){
      .main_tit{
        margin-bottom: 20px;
        div{
          &:first-child{
            font-size: 18px;
          }
          &:last-child{
            font-size: 10px;
          }        
        }
        .line{
          width: 40vw;
        }
      }
      .one_item{
        margin-bottom: 10px;
      }
      .bg_block{
        .brief{
          width: 100%;
          padding: 40px 30px;
          font-size: 14px;
          .b_tit{
            font-size: 27px;
          }
          .b_subtit{
            margin: 20px 0px;
          }
        }
      }
      .first_block,.second_block,.third_block,.fourth_block,.fifth_block,.sixth_block,.seventh_block{
        padding: 40px 30px;
      }
      .first_block{
        .the_cnt{
          width: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
      .third_block{
        .the_cnt{
          width: 100%;
          .one_item{
            width: 48%;
            height: 120px;
            padding: 10px;
            margin: unset;
            img{
              height: 60px;
            }
            .sub_tit{
              font-size: 12px;
              span{
                font-size: 14px;
              }
            }
          }
        }
      }
      .seventh_block{
        .the_cnt{
          width: 100%;
          flex-wrap: wrap;
        }
        img{
          margin-bottom: 10px;
          width: 48%;
        }
      }
    }
  }
 </style>
